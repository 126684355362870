import jwtDecode from 'jwt-decode';

// routes that need to to ignore authentication
const freeRoutesName = ['index', 'login', 'login-callback', 'login-providerKey', 'register', 'register-token', 'password-reset-token', 'password-forgot', 'join-accessId-nickname'];
// routes that can be logged or not
const optionalFreeRoutesName = ['client-collaboration-teamId', 'viewer-magazineId', 'maintenance'];

function isTokenExpired(token) {
  let date = new Date(jwtDecode(token).exp * 1000);
  return Date.now() >= date.getTime();
}

/**
 * NUXT ROUTER MIDDLEWARE
 * Check if JWT token is present (isLogged) to reconnect user on reload or later visit
 * Similar to axios interceptor but used for Nuxt navigation
 */
export default async function ({ store, redirect, route }) {
  const extractRouteName = route.name ? route.name.substr(0, route.name.indexOf('__')) : 'index';
  console.log('authenticated process for route ', extractRouteName)

  let isFreeRoute = freeRoutesName.indexOf(extractRouteName) != -1;
  let isOptionalFreeRoute = optionalFreeRoutesName.indexOf(extractRouteName) != -1;
  let isLogged = store.getters['api/isLogged'];
  let isLoggedAsSupport = store.getters['api/isLoggedAsSupport'];
  let isAdminRoute = store.getters['app/adminMode'];
  let tokenExpired = isLogged ? isTokenExpired(store.getters['api/getToken']) : false;

  if (tokenExpired) {
    console.log('API_CHECK auth middleware');
    let refreshToken = await store.dispatch('api/API_CHECK');
    if (!refreshToken) {
      // goto login page if token can be refreshed
      await store.dispatch('api/API_LOGOUT');
      isLogged = false;
      isLoggedAsSupport = false;
    }
  }
  if (isLogged && store.getters['api/permissions'].length === 0) {
    store.commit('api/UPDATE_PERMISSIONS');
  }

  // check come back to admin after logged as support
  if (isLogged && isAdminRoute && isLoggedAsSupport) {
    await store.dispatch('api/API_LOGOUT_SUPPORT');
  }

  // access to site index
  if (extractRouteName === 'index') {
    if (isLogged) {
      return redirect(
        store.getters['api/checkPerm']('admin:all')
          ? '/admin'
          : '/client'
      );
    }
    else {
      return redirect('/login');
    }
  }

  // if user is not authenticated, redirect to login, except for free routes
  if (isFreeRoute === false && isOptionalFreeRoute === false && isLogged === false) {
    console.warn('user try to access a non free route without login')
    // save current route for redirect after login
    store.commit('api/UPDATE_ROOT_FIELD', { key: 'forceDefaultRoute', value: { name: extractRouteName, params: route.params, query: route.query } });

    if (route.query && route.query.from) {
      return redirect('/login?email=' + encodeURIComponent(route.query.from));
    } else {
      return redirect('/login');
    }
  }

  // User is authenticated but try to reach a route for non authenticated, redirect to default route or logout
  if (isFreeRoute && isLogged) {
    const keepLogin = localStorage.getItem('mzkeeplogin');
    console.log('authenticated detect user is already logged. KeepLogin : ' + keepLogin)
    if (keepLogin) {
      return redirect(
        store.getters['api/checkPerm']('admin:all')
          ? '/admin'
          : '/client'
      );
    } else {
      store.dispatch('api/API_LOGOUT');
    }
  }
}
