import { render, staticRenderFns } from "./RequestPdfOrPublishModal.vue?vue&type=template&id=d9640d42&scoped=true&"
import script from "./RequestPdfOrPublishModal.vue?vue&type=script&lang=js&"
export * from "./RequestPdfOrPublishModal.vue?vue&type=script&lang=js&"
import style0 from "./RequestPdfOrPublishModal.vue?vue&type=style&index=0&id=d9640d42&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9640d42",
  null
  
)

export default component.exports