export default function ({ redirect, store, route }) {
  // /* restore token if present in local storage */
  // try {
  //   if (!(localStorage.getItem('mztoken') === null)) {
  //     console.log('API_CHECK session-check token');
  //     store.dispatch('api/API_CHECK').then((token) => {
  //       if (!token) {
  //         store.dispatch('api/API_LOGOUT');
  //         if (route && route.path && route.path == '/login') {
  //           window.location.reload();
  //         } else {
  //           redirect('/login');
  //         }
  //       }
  //     });
  //   }
  // }
  // catch (e) {
  //   console.error(e.stack);
  // }

  /* switch session is active process */
  store.dispatch("api/API_SESSION_ACTIVE", true);

  window.addEventListener('beforeunload', (event) => {
    store.dispatch("api/API_SESSION_ACTIVE", false);
  }, false);
}