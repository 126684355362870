import Vue from 'vue'
import WebFontLoader from "webfontloader";

const nativefonts = [];//disable native font

Vue.prototype.$fontManager = {

  loadedFonts: [],
  defaultFonts: nativefonts,

  /**
   * Parse Font model and convert it to WebFontLoader format
   * @param {*} fontList
   */
  parseFontList(fontList) {
    let wflObj = {};

    for (let i = 0; i < fontList.length; i++) {
      if (!fontList[i].name || nativefonts.includes(fontList[i].name) || this.loadedFonts.includes(fontList[i].name)) {
        continue;
      }

      let options = '';
      if (fontList[i].provider !== 'custom') {
        if (fontList[i].styles && fontList[i].styles.length > 0) {
          options += ':' + fontList[i].styles.join(',');
        }
        if (fontList[i].subsets && fontList[i].subsets.length > 0) {
          options += ':' + fontList[i].subsets.join(',');
        }
      }

      switch (fontList[i].provider) {

        case 'typekit':
          if (!wflObj.typekit) {
            wflObj.typekit = {
              id: fontList[i].id + options,
              api: '//use.edgefonts.net'
            }
          } else {
            wflObj.typekit.id += ';' + fontList[i].name + options
          }
          break;

        case 'custom':
          let url = fontList[i].url;
          const pos = url.indexOf('amazonaws.com/');
          if (pos >= 0) {
            // bugfix previous upload fonts with aws s3 bucket inside url
            url = process.env.COMMON_AWS_S3_URL + url.substr(pos + 14);
          } else {
            url = process.env.COMMON_AWS_S3_URL + url;
          }

          if (!wflObj.custom) {
            wflObj.custom = {
              families: [fontList[i].name + options],
              urls: [url]
            }
          } else {
            wflObj.custom.families.push(fontList[i].name + options);
            wflObj.custom.urls.push(url);
          }
          break;

        case 'google':
        default:
          if (!wflObj.google) {
            wflObj.google = {
              families: [fontList[i].name + options]
            }
          } else {
            wflObj.google.families.push(fontList[i].name + options);
          }
          break;
      }
    }

    return wflObj;

  },

  loadFontList(fontList) {
    let wflObj = this.parseFontList(fontList);

    // if no font has to be loaded, return
    if (Object.keys(wflObj).length === 0 && wflObj.constructor === Object) {
      return Promise.resolve();
    }

    let fontNames = fontList.map(fnt => fnt.name);

    return new Promise((resolve, reject) => {
      WebFontLoader.load({
        ...wflObj,
        classes: false,
        active: () => {
          this.loadedFonts.push(...fontNames);
          console.log("font is loaded", fontNames);
          resolve(fontNames);
        },
        inactive: (e) => {
          console.log("error loading font", e)
          reject();
        }
      });
    })

  },

  loadFont: function (fontName) {
    // Font already registered : return
    if (this.loadedFonts.includes(fontName)) {
      console.log("font already loaded")
      return Promise.resolve(fontName);
    }

    // Use Local Font - Font need to be installed on both client and server side!
    if (nativefonts.includes(fontName)) {
      return this.fontLoaded(fontName);
    }

    console.log("...loading font:", fontName);

    return new Promise((resolve, reject) => {
      WebFontLoader.load({
        classes: false,
        google: {
          families: [fontName]
        },
        active: () => {
          resolve(fontName);
        },
        inactive: () => {
          reject();
        }
      });
    })
      .then(() => { return this.fontLoaded(fontName) })
      .catch(e => {
        console.log(e);
        return Promise.reject("font loading failed " + fontName);
      });
  },

  fontLoaded: function (fontName) {
    this.loadedFonts.push(fontName);
    console.log("webfonts loaded", fontName, this.loadedFonts);
    return Promise.resolve(fontName)
  }
}