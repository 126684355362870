<template>
  <div class="notification">
    <div class="icon"></div>
    <div>
      <div v-html="message"></div>

      <ul v-if="pagesList !== null">
        <li v-for="page in pagesList" :key="page.pageId">
          <el-link
            type="primary"
            :underline="false"
            @click="
              goto({
                name: 'client-magazine-edit-magazineId',
                params: {
                  magazineId: notification.data.magazineId,
                },
                query: {
                  page: page.pageId,
                },
              })
            "
          >
            {{
              page.pageName
                ? page.pageName
                : translate(
                    page.double
                      ? "public.edit_magazine.double_page_name"
                      : "public.edit_magazine.page_name",
                    {
                      number: page.pageNumber,
                    }
                  )
            }}
          </el-link>
        </li>
      </ul>

      <div class="time">
        {{
          new Date(notification.createdAt)
            | moment(translate("public.notifications.dateFormat"))
        }}
        <el-link
          v-if="go2Params"
          type="primary"
          :underline="false"
          class="link"
          @click="goto(go2Params)"
        >
          <i class="el-icon-caret-right"></i>
        </el-link>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationTypes from "~/common/data/NotificationTypes";

export default {
  name: "Notification",

  props: ["notification", "i18n", "go2Route"],
  data() {
    return {
      notifyElementIo: null,
    };
  },

  computed: {
    message() {
      let msg = "";

      switch (this.notification.type) {
        case NotificationTypes.InvitCollaboration:
          msg = this.translate(
            "public.notifications.message.invitCollaboration",
            {
              userName: this.notification.data.userName,
              magazineTitle: this.notification.data.magazineTitle,
              magazineIssue: this.notification.data.magazineIssue,
            }
          );
          break;

        case NotificationTypes.AssignPages:
          msg = this.translate("public.notifications.message.assignPages", {
            userName: this.notification.data.userName,
            magazineTitle: this.notification.data.magazineTitle,
            magazineIssue: this.notification.data.magazineIssue,
          });
          break;

        case NotificationTypes.ChangeMagazineChief:
          msg = this.translate(
            "public.notifications.message.changeMagazineChief",
            {
              userName: this.notification.data.userName,
              previousChiefName: this.notification.data.previousChiefName,
              magazineTitle: this.notification.data.magazineTitle,
              magazineIssue: this.notification.data.magazineIssue,
            }
          );
          break;

        case NotificationTypes.MagazinePdfGenerateComplete:
          msg = this.translate(
            "public.notifications.message.magazinePdfGenerateComplete",
            {
              pdfVersion: this.notification.data.pdfVersion,
              magazineName: this.notification.data.magazineName,
            }
          );
          break;

        case NotificationTypes.WorkspaceExportMagazinesZipComplete:
          msg = this.translate(
            "public.notifications.message.workspaceExportMagazinesZipComplete",
            {
              zipVersion: this.notification.data.zipVersion,
              workspaceName: this.notification.data.workspaceName,
            }
          );
          break;

        default:
          msg = "Unknow notification type";
          break;
      }

      // replace <b></b> by specific font color
      // msg = msg
      //   .replace(/<b>/gi, "<span class='blue'>")
      //   .replace(/<\/b>/gi, "</span>");

      return msg;
    },

    pagesList() {
      if (
        (this.notification.type === NotificationTypes.AssignPages ||
          this.notification.type === NotificationTypes.InvitCollaboration) &&
        this.notification.data.assignedPages &&
        this.notification.data.assignedPages.length > 0
      ) {
        return this.notification.data.assignedPages;
      }
      return null;
    },

    go2Params() {
      let params = null;

      switch (this.notification.type) {
        case NotificationTypes.InvitCollaboration:
        case NotificationTypes.AssignPages:
          if (this.pagesList === null) {
            params = {
              name: "client-magazine-edit-magazineId",
              params: {
                magazineId: this.notification.data.magazineId,
              },
              query: {
                flatplan: "extended",
              },
            };
          }
          break;
        case NotificationTypes.MagazinePdfGenerateComplete:
          params = {
            name: "client-magazine-edit-magazineId-publication",
            params: {
              magazineId: this.notification.data.magazineId,
            },
          };
          break;
        case NotificationTypes.WorkspaceExportMagazinesZipComplete:
          params = {
            name: "client-workspace",
          };
          break;
      }

      return params;
    },
  },
  methods: {
    setNotifyElementIo(element) {
      this.notifyElementIo = element;
    },
    translate(key, attr) {
      if (this.$i18n) {
        return this.$t(key, attr);
      }

      // override transalte function cause bug on $createElement
      if (this.i18n) {
        var values = [],
          len = arguments.length - 1;
        while (len-- > 0) values[len] = arguments[len + 1];

        var i18n = this.i18n;
        return i18n._t.apply(
          i18n,
          [key, i18n.locale, i18n._getMessages(), this].concat(values)
        );
      }

      return key;
    },

    goto(params) {
      if (this.notifyElementIo) {
        this.notifyElementIo.close();
      }

      if (this.$go2Route) {
        return this.$go2Route(params);
      }

      // override go2Route function cause bug on $createElement
      if (this.go2Route) {
        return this.go2Route(params);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.notification {
  font-size: 0.75rem;

  & ul {
    margin-block-start: 0.25rem;
    & .el-link {
      font-size: 0.75rem;
    }
  }
}

.time {
  color: $public-color-grey-1;
  font-size: 0.65rem;
}

.link {
  float: right;
  margin-top: -5px;
}

/deep/.blue {
  color: $public-color-blue;
}
</style>