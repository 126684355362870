import { WebsocketEvents } from "~/common/data/WebsocketEvents"

export default (ctx) => {
  const { store, app, redirect, route } = ctx
  store.watch((state) => state.magazine.magazine && state.magazine.magazine._id, (newValue, oldValue) => {
    if (oldValue !== newValue) {
      if (oldValue) {
        app.$socket.leaveMagazine(oldValue)
      }
      if (newValue) {
        if (!store.state.magazine.magazineLoadType || ['client', 'public'].indexOf(store.state.magazine.magazineLoadType) === -1) {
          app.$socket.joinMagazine(newValue);
        }
      }
    }
  })

  // socket connection management
  if (store.state.api.user.websocketToken) {
    console.log('WEBSOCKET first connect');
    app.$socket.connect(store.state.api.user.websocketToken);
  }
  store.watch(state => state.api.user.websocketToken, (newValue) => {
    console.log('WEBSOCKET change connect to ', newValue);
    if (newValue) {
      // disconnect from ws
      app.$socket.close().then(() => {
        // connect to ws
        app.$socket.connect(newValue);
      });
    } else {
      // disconnect from ws
      app.$socket.close();
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdateUserAccess, data => {
    store.dispatch("api/API_CHECK").then((token) => {
      if (!token) {
        store.dispatch('api/API_LOGOUT');
        if (route && route.path && route.path == '/login') {
          window.location.reload();
        } else {
          redirect('/login');
        }
      }
    });
  })

  app.$socket.addListener(WebsocketEvents.LogoutUser, data => {
    if (store.state.api.user.sessionId === data.sessionId) {
      if (store.state.api.supportUser) {
        store.dispatch("api/API_LOGOUT_SUPPORT").then(() => {
          redirect('/admin');
        });
      } else {
        store.dispatch("api/API_LOGOUT").then(() => {
          redirect('/login');
        });
      }
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdateThumbnail, data => {
    if (data.page) {
      app.$bus.$emit("thumb_page_change_" + data.page);
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdateWorkspace, data => {
    if (store.state.workspace.workspace._id === data.workspaceId) {
      store.dispatch("workspace/LOAD_WORKSPACE", { workspaceId: data.workspaceId });
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdateMagazine, data => {
    if (data.magazine && store.state.magazine.magazine && store.state.magazine.magazine._id === data.magazine) {
      store.dispatch("magazine/LOAD_MAGAZINE", { magazineId: data.magazine, loadType: 'current' });
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdateMagazinesList, data => {
    if (store.state.magazine.userMagazines) {
      store.dispatch("magazine/LOAD_USER_MAGAZINES");
    }
    if (store.state.magazine.organizationMagazines) {
      store.dispatch("magazine/LOAD_ORGANIZATION_MAGAZINES");
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdateLibrary, data => {
    if (data.library && store.state.library.library && store.state.library.library._id === data.library) {
      store.dispatch("library/LOAD_LIBRARY", { libraryId, full: store.state.library.hasLoadedFullLibrary });
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdatePage, data => {
    // refresh magazine data on page change
    if (data.magazine && store.state.magazine.magazine && store.state.magazine.magazine._id === data.magazine) {
      store.dispatch("magazine/LOAD_MAGAZINE", { magazineId: data.magazine, loadType: 'current' });
    }

    // keep it for later, we don't support refresh at the moment
    /*
    console.log("we need to refresh a page :D")
    const store = ctx.store;
    if (store.state.page.page && store.state.page.page._id === data.page) {
      // store.dispatch("page/REFRESH_PAGE", data.page)
    }
    if (store.state.magazine.magazine && store.state.magazine.magazine.pageOrder.find(page => page._id === data.page)) {
      console.log("PAGE IS DISPLAYED")
      store.dispatch("magazine/LOAD_MAGAZINE", { magazineId: data.magazine });
    }
    */
  })

  app.$socket.addListener(WebsocketEvents.UpdateCollaboration, data => {
    if (data.collaboration) {
      store.dispatch("collaboration/LOAD_COLLABORATION", data.collaboration)
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdateOrder, data => {
    if (data.order) {
      store.dispatch("order/LOAD_ORDER", { orderId: data.order, force: true });
    }
  })
  app.$socket.addListener(WebsocketEvents.RemoveOrder, data => {
    if (data.orders) {
      for (let orderId of data.orders) {
        store.commit("order/REMOVE_ORDER", orderId);
      }
    }
  })

  app.$socket.addListener(WebsocketEvents.NewNotification, data => {
    if (data) {
      store.commit("notification/ADD_NOTIFICATION", data)
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdatePdfStatus, data => {
    if (data.id && data.version
      && store.state.magazine.pdfStatus
      && store.state.magazine.pdfStatus.id === data.id && store.state.magazine.pdfStatus.version === data.version) {
      store.commit('magazine/UPDATE_ROOT_FIELD', { key: 'pdfStatus', value: data });
    }
  })

  app.$socket.addListener(WebsocketEvents.UpdateMaintenance, data => {
    if (data) {
      store.commit('app/UPDATE_SETTINGS', { key: 'maintenanceMode', value: data.maintenanceMode });
    }
  })


}