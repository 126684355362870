<template>
  <div>
    <el-dialog
      :title="$t('public.upgrade_offer_modal.title')"
      :visible.sync="displayDialog"
      custom-class="el-fullheigth-dialog"
      append-to-body
      width="80%"
    >
      <el-row v-loading="loading">
        <div v-if="displayStripeCheckout">
          <StripeCheckout :stripeClientSecret="stripeCheckoutClientSecret" />
        </div>
        <div v-else>
          <el-row v-if="filteredOffers.length === 0" class="m-2">
            <el-col class="mt-2">
              <span class="text-style-2">{{
                $t("public.create_magazine.empty_offer_list")
              }}</span>
            </el-col>
          </el-row>
          <el-row v-else type="flex" justify="center">
            <ItemOffer
              v-for="offer in filteredOffers"
              :key="offer._id"
              :offer="offer"
              :isCurrent="offer.isCurrent"
              :alternateColor="offer.isCurrent"
              :disabled="offer.isDisabled"
              @select="onSelectOffer"
            />
          </el-row>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import ItemOffer from "~/components/offer/ItemOffer";
import StripeCheckout from "~/components/stripe/StripeCheckout";

export default {
  name: "UpgradeOfferModal",

  components: { ItemOffer, StripeCheckout },

  data() {
    return {
      displayDialog: false,
      loading: false,
      displayStripeCheckout: false,
      stripeCheckoutClientSecret: null,
    };
  },

  computed: {
    ...mapState("magazine", ["magazine"]),
    ...mapGetters("api", ["checkPerm"]),
    ...mapState("offer", ["offers"]),

    filteredOffers() {
      if (!this.offers) {
        return [];
      }

      let isDisabled = true;
      return [...this.offers]
        .sort((offer1, offer2) => {
          return offer1.upgradeIndex - offer2.upgradeIndex;
        })
        .map((offer) => {
          offer.isCurrent =
            this.magazine.recursiveActiveOffer &&
            this.magazine.recursiveActiveOffer._id === offer._id;
          offer.isDisabled = isDisabled;

          if (offer.isCurrent) {
            // only active next offer from current
            isDisabled = false;
          }

          return offer;
        });
    },
  },

  methods: {
    ...mapMutations({
      updateMagazineField: "magazine/UPDATE_FIELD",
    }),
    ...mapActions({
      saveMagazine: "magazine/SAVE_MAGAZINE",
      loadOffers: "offer/LOAD_OFFERS",
    }),

    show() {
      this.displayDialog = true;
      this.loading = true;
      this.displayStripeCheckout = false;
      this.stripeCheckoutClientSecret = null;

      this.loadOffers({ filter: { isPublic: true } }).then(() => {
        this.loading = false;
        console.log("mag", JSON.parse(JSON.stringify(this.magazine)));
      });
    },
    hide() {
      this.displayDialog = false;
    },

    onSelectOffer(offer) {
      console.log("select offer", JSON.parse(JSON.stringify(offer)));

      this.updateMagazineField({ key: "offer", value: offer._id });
      this.saveMagazine().then((result) => {
        this.saveInProgress = false;

        if (result) {
          if (result.checkout) {
            console.log("instantiate checkout", result);
            this.stripeCheckoutClientSecret = result.checkout.clientSecret;
            this.displayStripeCheckout = true;
          } else {
            this.hide();
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>