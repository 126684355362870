<template>
  <div class="inactive-alert">
    <el-alert v-show="displayAlert" type="warning" :closable="false" center>
      <span slot="title">
        {{ $t("public.inactiveAlert.message") }}
      </span>
      <div>
        <el-row type="flex" justify="space-around" class="mt-2 mb-0">
          <el-button type="danger" size="small" @click="onClickTerminate">
            {{ $t("public.inactiveAlert.terminateSession") }}
          </el-button>
          <el-button type="success" size="small" @click="onClickContinue">
            {{ $t("public.inactiveAlert.continueSession") }}
          </el-button>
        </el-row>
      </div>
    </el-alert>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "InactiveUserAlert",

  data() {
    return {
      alertTimeoutId: null,
      logoutTimeoutId: null,
      displayAlert: false,
    };
  },
  computed: {
    ...mapGetters("api", ["getTokenIdleTimeout"]),
  },
  mounted() {
    this.startInactiveTimer();
    this.$bus.$on("user_is_active", this.startInactiveTimer);
  },
  beforeDestroy() {
    this.$bus.$off("user_is_active", this.startInactiveTimer);
    this.stopInactiveTimer();
  },
  methods: {
    ...mapActions({
      doLogout: "api/API_LOGOUT",
      checkToken: "api/API_CHECK",
    }),

    startInactiveTimer() {
      this.displayAlert = false;
      this.stopInactiveTimer();

      if (this.getTokenIdleTimeout && this.getTokenIdleTimeout > 0) {
        const alertTime = this.getTokenIdleTimeout * 0.8 * 1000,
          logoutTime = this.getTokenIdleTimeout * 1000;

        this.alertTimeoutId = setTimeout(this.displayInactiveAlert, alertTime);
        this.logoutTimeoutId = setTimeout(this.autoLogoutUser, logoutTime);
      }
    },
    stopInactiveTimer() {
      if (this.alertTimeoutId !== null) {
        clearTimeout(this.alertTimeoutId);
        this.alertTimeoutId = null;
      }
      if (this.logoutTimeoutId !== null) {
        clearTimeout(this.logoutTimeoutId);
        this.logoutTimeoutId = null;
      }
    },
    displayInactiveAlert() {
      this.displayAlert = true;
    },
    autoLogoutUser() {
      this.displayAlert = false;

      this.doLogout({
        saveConnectedEmail: true,
      }).then(() => {
        this.$go2Route({ name: "login" });
      });
    },

    onClickTerminate() {
      this.doLogout().then(() => {
        this.$go2Route({ name: "login" });
      });
    },
    onClickContinue() {
      this.startInactiveTimer();
      this.checkToken();
    },
  },
};
</script>

<style scoped>
.inactive-alert {
  z-index: 11;
  position: absolute;
  top: 25px;
  width: 50vw;
  left: 25vw;
}
</style>