import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  createPersistedState({
    key: 'madmagz-athena',
    paths: [
      "order",
      "format",
      //  "magazine",
      "library",
      "page",
      "theme",
      "palette",
      "fabric",
      "user",
      "app",
      "api",
    ]
  })(store)
}