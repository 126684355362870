import Vue from 'vue'

// Reactive value to use in the plugin
const onlineState = Vue.observable({
  online: true,
  retries: 0,
  lastRetryTime: 0,
  nextRetryTime: 0
})

// Determine how long we have to wait, based on the number of retries
function getDelay(retries) {
  if (retries < 10)
    return 1000
  else if (retries < 15)
    return 10000
  else return 60000
}

export default ({ $axios, store }) => {
  Vue.prototype.$online = onlineState

  let timeoutCheck,
    timeoutTriggerOffline;

  function setTimeoutCheck() {
    const retryDelta = getDelay(onlineState.retries)
    onlineState.retries++
    onlineState.lastRetryTime = Date.now()
    onlineState.nextRetryTime = Date.now() + retryDelta

    store.dispatch("api/API_STATUS")
    timeoutCheck = setTimeout(setTimeoutCheck, retryDelta)
  }

  const triggerOffline = function () {
    clearTimeout(timeoutTriggerOffline);

    timeoutTriggerOffline = setTimeout(() => {
      if (onlineState.online) {
        onlineState.online = false
        setTimeoutCheck()
      }
    }, 1500);
  }

  const triggerOnline = function () {
    clearTimeout(timeoutCheck)
    clearTimeout(timeoutTriggerOffline)

    if (!onlineState.online) {
      onlineState.online = true
      onlineState.retries = 0
      onlineState.lastRetryTime = 0
      onlineState.nextRetryTime = 0
    }
  }

  $axios.interceptors.response.use(response => {
    triggerOnline()
    return response
  }, error => {
    if (!error.response) {
      // network error (server is down or no internet)
      triggerOffline()
    } else {
      triggerOnline()
      // http status code
      const code = error.response.status
      if (code === 502) { //aws load balancer timeout
        triggerOffline()
      }
    }
    return Promise.reject(error)
  })
}