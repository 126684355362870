
/**
 * Redirect root path to /login
 */
export default async function ({ store, redirect, route }) {
  console.log("route: " + route.fullPath, route)
  if (route.fullPath === '/') {
    return redirect('/login');//{ name: 'login', query: { ...route.query, ...route.params } });
  }
}
